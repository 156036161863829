.Page {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Classes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.Offerings {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0px 20px 20px 20px;
}

.Card {
    margin: 20px;
    width: 300px;
    @media (min-width:1025px) {
        width: 400px !important;
    } 
}

.MainCard {
    width: 300px !important;
    margin: 20px;
}
.CardText {
    text-align: left;
}

.Modal {
    top: '50%';
    left: '50%';
    background-color: '#ffffff';
}

.CardHeader {
    background-color: '#1c3144 !important';
    color: '#ffffff !important'
}

.Heading {
    padding: 0px 10px 0px 10px;
}

.Description {
    margin: 2px !important;
}

@media screen and (min-width: 1158px) {
    .MainCard {
        min-width: 350px;
    }
}