.Contact {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.InfoContainer {
    text-align: center;
}

.MapContainer {
    text-align: center;
}

.Icon {
    padding-right: 5px;
}

.IconContainer {
    display: flex;
    justify-content: center;
    margin: 10px;
}

.ContactInfo {
    margin: 10px;
}

.ContactMe {
    margin: 5px !important;
}

.Map {
    max-width: 80%;
    max-height: 80%;
    margin: 5px 10px 10px 10px;
}

.Charleston {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

@media screen and (min-width: 1025px) {
    .Map  {
        max-width: 350px;
        max-height: 350px;
    }
}

@media screen and (min-width: 450px) and (max-width:1024px) {
    .Map  {
        max-width: 350px;
        max-height: 350px;
    }
}