.Instructor {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.Headshot {
    margin-bottom: 10px;
    max-width: 90%;
    align-self: center;
    border-radius: 50%;
}

.Content {
    padding-bottom: 20px;
}

.HeadshotText {
    display: flex;
    flex-direction: column;
}

.Header {
    align-self: center;
    margin-bottom: 10px !important;
}

@media screen and (min-width: 1025px) {
    .Instructor {
        flex-direction: row;
    }
    .Headshot {
        max-width: 25%;
    }
    .HeadshotText {
        margin: 24px;
    }
}

@media screen and (min-width: 600px) and (max-width:1024px) {
    .Instructor {
        flex-direction: row;
    }
    .Headshot {
        max-width: 40%;
    }
    .HeadshotText {
        margin: 24px;
    }
}