.Home {
    display: flex;
    flex-direction: column;
    text-align: left;
    max-width: 1050px;
    margin-left: auto;
    margin-right: auto;
}

.HomeIcons {
    padding-bottom: 20px;
}

.IconContainer {
    display: flex;
}

.Icon {
    padding-right: 5px;
}

.HomeContent {
    padding-top: 30px;
    text-align: center;
}

.HomeAsset {
    max-width: 600px;
    align-self: center;
}

.Quotes{
    margin: 10px;
    display: flex;
    align-content: center;
}

.Quote {
    align-self: center;
}

img:hover {
    cursor: pointer;
}